export default {
    // ENDPOINT: "https://clinic-service-demo.yuzudigital.com/",

    // UAT ของตัวแทน
    ENDPOINT: "https://jn-service.poonework.com/",

    // DEV
    // ENDPOINT: "http://localhost:8081/",

    // Client
    // ENDPOINT_CLIENT: "https://demo-clinic.yuzudigital.com/",

    // CHOMTANA MAIN SERVICE .91 ของจอมธนา
    // ENDPOINT: "http://pos.J&N.com:15380/"

};
